import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ScrollBtn from "../components/scroll-btn"
import ReactMarkdown from "react-markdown"
import Header from "../components/header"
import Footer from "../components/footer"

import { graphql } from "gatsby"
import { rewriteSlug } from "../utils/rewriteSlug"
import { useEffect } from "react"
import { isBrowser } from "../utils/isBrowser"
import { scrollToSection } from "../utils/scrollToSection"

const ApproachPage = props => {
  const {
    Title,
    IntroText,
    OurMissionText,
    ServicesList,
    ServicesNotice,
    PartnerSection,
    locale,
  } = props.data.strapiApproachPage

  useEffect(() => {
    if (isBrowser()) {
      const sections = document.querySelectorAll(
        "#gatsby-focus-wrapper section"
      )
      let currentSection = 0

      window.addEventListener("keyup", initScroller, true)

      function initScroller(event) {
        event.preventDefault()
        event.stopPropagation()

        if (event.code === "ArrowDown") {
          if (currentSection < sections.length - 1)
            currentSection = currentSection + 1

          scrollToSection(sections, currentSection)
        }
        if (event.code === "ArrowUp") {
          if (currentSection > 0) currentSection = currentSection - 1

          scrollToSection(sections, currentSection)
        }
      }

      return function cleanup() {
        window.removeEventListener("keyup", initScroller, true)
      }
    }
  })

  return (
    <>
      <Header
        locale={locale}
        siteTitle={props.data.site.siteMetadata?.title || `Title`}
        data={props.data.allStrapiHeader.nodes.find(
          header => header.locale === props.pageContext.locale
        )}
        localizations={
          props.data.strapiApproachPage.localizations.data[0].attributes
        }
        contactSlug={props.data.strapiContact.slug}
        currentPage={props.data.strapiApproachPage.slug}
      />
      <Layout>
        <Seo
          lang={locale}
          description={IntroText.data.IntroText}
          title={Title}
        />
        <section className="single-page__hero flex flex-col justify-center lg:min-h-[720px] px-6 relative">
          <div className="text-center my-8">
            <div>
              <div className="hero-circles-container">
                <div className="circle circle--dashed circle--1 circle--1-dashed"></div>
                <div className="circle circle--dashed circle--2 circle--2-dashed"></div>
                <div className="circle circle--dashed circle--3 circle--3-dashed"></div>
                <div className="circle circle--dashed circle--4 circle--4-dashed"></div>
              </div>
            </div>
            <h1 className="single-page__hero-title">{Title}</h1>

            <div className="single-page__hero-text">
              <ReactMarkdown>{IntroText.data.IntroText}</ReactMarkdown>
            </div>
          </div>
          <div className="scrollButtonContainer">
            <ScrollBtn
              beforeText={
                props.data.strapiGeneralTranslation.ScrollDownOrJustUse
              }
              afterText={props.data.strapiGeneralTranslation.Keys}
            />
          </div>
        </section>
        <section className="single-page__our-mission-section our-mission-section">
          <div className="our-mission-section__column">
            <ReactMarkdown>{OurMissionText.data.OurMissionText}</ReactMarkdown>
          </div>
        </section>
        <section className="single-page__approach-phases approach-phases-section">
          <div className="approach-phases">
            {ServicesList.map((phase, index) => {
              return (
                <div
                  key={index}
                  className="approach-phases__approach-phase approach-phase"
                >
                  <span className="approach-phase__number">0{index + 1}</span>
                  <img
                    className="approach-phase__image"
                    src={phase.Image.url}
                    alt={phase.Image.alternativeText}
                    loading="lazy"
                  />
                  <h3 className="approach-phase__title">{phase.Heading}</h3>
                  <p className="approach-phase__text">{phase.Description}</p>
                </div>
              )
            })}
          </div>
          {ServicesNotice && (
            <div className="approach-phases-text">
              {ServicesNotice.data.ServicesNotice}
            </div>
          )}
        </section>
        {PartnerSection && (
          <section className="single-page__partners-section partners-section">
            <div className="partners-section__wrapper">
              <div className="partners-section__content">
                <h2 className="partners__headline">
                  {PartnerSection.Headline}
                </h2>
                <div className="partners__text">
                  <ReactMarkdown>{PartnerSection.Text.data.Text}</ReactMarkdown>
                </div>
              </div>
              <div className="partners-section__article">
                <Link
                  to={
                    "/" +
                    rewriteSlug(
                      PartnerSection.article.slug,
                      props.data.strapiBlogPage.slug
                    )
                  }
                  className="article-card"
                >
                  <div className="article-card__image-wrapper">
                    <img
                      className="article-card__image"
                      src={PartnerSection.article.cover.url}
                      alt={PartnerSection.article.cover.alternativeText}
                      loading="lazy"
                    />
                  </div>
                  <p className="article-card__category">
                    {PartnerSection.article.category.name}
                  </p>
                  <h3 className="article-card__title">
                    {PartnerSection.article.title}
                  </h3>
                  <div className="article-card__author">
                    <img
                      className="article-card__author-image"
                      src={PartnerSection.article.author.avatar.url}
                      alt={PartnerSection.article.author.avatar.alternativeText}
                      loading="lazy"
                    />
                    <div className="article-card__author-name">
                      <span>{props.data.strapiGeneralTranslation.By}</span>
                      <p>{PartnerSection.article.author.name}</p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </section>
        )}
      </Layout>
      <Footer
        contactSlug={props.data.strapiContact.slug}
        locale={locale}
        cookieSettingsText={props.data.strapiGeneralTranslation.CookieSettings}
        socialLinks={props.data.strapiGeneralSetting.SocialLinks}
        data={props.data.allStrapiFooter.nodes.find(
          header => header.locale === props.pageContext.locale
        )}
      />
    </>
  )
}

export const query = graphql`
  query ($slug: String, $locale: String) {
    site {
      siteMetadata {
        title
      }
    }

    strapiContact(locale: { eq: $locale }) {
      slug
    }

    strapiBlogPage(locale: { eq: $locale }) {
      slug
    }

    strapiApproachPage(slug: { eq: $slug }, locale: { eq: $locale }) {
      locale
      slug
      localizations {
        data {
          attributes {
            locale
            slug
          }
        }
      }
      Title
      IntroText {
        data {
          IntroText
        }
      }
      OurMissionText {
        data {
          OurMissionText
        }
      }
      ServicesList {
        Description
        Heading
        Image {
          url
          alternativeText
        }
      }
      ServicesNotice {
        data {
          ServicesNotice
        }
      }
      PartnerSection {
        Headline
        Text {
          data {
            Text
          }
        }
        article {
          title
          slug
          cover {
            url
            alternativeText
          }
          publishedAt
          author {
            name
            avatar {
              url
              alternativeText
            }
          }
          category {
            name
          }
        }
      }
    }

    allStrapiFooter {
      nodes {
        locale
        ContactText
        Heading
        RightsClaim
        SocialHeading
        Text
        Secondary {
          Link
          Text
        }
        Location {
          Heading
          Text {
            data {
              Text
            }
          }
        }
        Footer {
          Link
          Text
        }
      }
    }

    allStrapiHeader {
      nodes {
        locale
        MobileContactButtonText
        MobileContactHeading
        MobileContactText
        Links {
          Link
          Text
        }
        ContactText
      }
    }

    strapiGeneralSetting(locale: { eq: $locale }) {
      ContactSection {
        Headline
        TextPlainHTML {
          data {
            TextPlainHTML
          }
        }
      }
      SocialLinks {
        Facebook
        LinkedIn
        Twitter
      }
      SocialShareLinks {
        Facebook
        LinkedIn
        Twitter
      }
    }

    strapiGeneralTranslation(locale: { eq: $locale }) {
      AllRightsReserved
      BackToBlog
      By
      Client
      CopiedToClipboard
      Keys
      ReadMore
      ScrollDownOrJustUse
      ViewProject
      CookieSettings
    }
  }
`

export default ApproachPage
